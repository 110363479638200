import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { UserContextService } from '../context/user.context.service';
import { CommonUtil } from '../util/common.util';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _router: Router, private _userContextService: UserContextService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                CommonUtil.hideAppLoader();
                switch ((<HttpErrorResponse>error).status) {
                    case 401:
                        if (this._router.url === '/reset-password?verify=true') break;

                        this._userContextService.clear();
                        this._router.navigate(['login']);
                        break;
                    default:
                        break;
                }

                return throwError(() => error);
            })
        );
    }
}
