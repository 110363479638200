import { HttpClient, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';

export abstract class BaseService<T> {
    protected httpClient: HttpClient;
    protected serviceBaseUrl: string;
    protected environment: any;

    protected constructor(
        protected injector: Injector,
        serviceBaseUrl: string
    ) {
        this.httpClient = injector.get(HttpClient);
        this.environment = injector.get('environment');
        this.serviceBaseUrl = serviceBaseUrl;
    }

    protected getHttpParams(): HttpParams {
        return new HttpParams();
    }
}
