import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IReferenceTimeZoneModel } from '@libs/vc-reference-data-lib';
import { IUserModel, Language, LanguageHelper, Theme, ThemeHelper } from '@libs/vc-core-lib';
import { UserSettings } from '../account-settings-view/account-settings-view.component';
import { isEqual } from 'lodash-es';

@Component({
    selector: 'vc-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent {
    showChangePasswordDialog: boolean = false;
    showResetSettingsDialog: boolean = false;
    showNotification: boolean = false;
    username!: string;
    timezone!: IReferenceTimeZoneModel | null;
    prevTimeZone!: IReferenceTimeZoneModel | null;

    @Input()
    set user(user: IUserModel) {
        this._user = user;
        this.username = user?.userKey?.username ?? '';
        this._initTimeZone();
    }
    get user(): IUserModel {
        return this._user;
    }
    private _user!: IUserModel;

    @Input()
    orgMFAEnabled = false;

    @Input()
    showEnableMFA = false;

    @Input()
    showDisableMFA = false;

    @Input()
    mfaEnabled = false;

    @Input()
    previousMfaEnabled = false;

    @Input()
    set timezones(timezones: IReferenceTimeZoneModel[]) {
        this._timezones = timezones;
        this._initTimeZone();
    }
    get timezones(): IReferenceTimeZoneModel[] {
        return this._timezones;
    }
    private _timezones!: IReferenceTimeZoneModel[];

    @Input()
    themes!: Theme[];

    @Input()
    theme!: Theme | null;

    @Input()
    prevTheme!: Theme | null;

    @Input()
    languages!: Language[];

    @Input()
    language!: Language | null;

    @Input()
    prevLanguage!: Language | null;

    @Input()
    qrData!: string;

    @Output()
    timeZoneChange = new EventEmitter<IUserModel>();

    @Output()
    userSettingsChange = new EventEmitter<UserSettings>();

    @Output()
    disableMFA = new EventEmitter<string>();

    @Output()
    mfaComplete = new EventEmitter<void>();

    settingsChange() {
        this.showNotification = !isEqual(
            { theme: this.theme, language: this.language, timezone: this.timezone },
            { theme: this.prevTheme, language: this.prevLanguage, timezone: this.prevTimeZone }
        );
    }

    timezoneRenderer = (timezone: IReferenceTimeZoneModel) => {
        return timezone ? `${timezone.offset} (${timezone.timeZone})` : '';
    };

    getLanguageLabel(lang: Language): string {
        return LanguageHelper.getLanguageLocale(lang);
    }

    themeRenderer = (theme: Theme) => ThemeHelper.getThemeLocale(theme);

    onEnableVisibleChange(value: boolean) {
        this.showEnableMFA = value;
    }

    onDisableVisibleChange(value: boolean) {
        this.showDisableMFA = value;
    }

    beginMFAFlow(enable: boolean) {
        if (enable) {
            this.showEnableMFA = true;
        } else {
            this.showDisableMFA = true;
        }
        this.mfaEnabled = enable;
    }

    saveSettings() {
        if (this.timezone?.timeZone !== this.prevTimeZone?.timeZone) {
            this.user.timeZone = this.timezone?.timeZone ?? '';
            this.timeZoneChange.emit(this.user);
        }

        const settings: UserSettings = {
            language: this.language ?? Language.EN,
            theme: this.theme ?? Theme.light,
        };
        this.userSettingsChange.emit(settings);
    }

    resetSettings() {
        this.timezone = this.prevTimeZone;
        this.theme = this.prevTheme;
        this.language = this.prevLanguage;
    }

    private _initTimeZone() {
        if (!this.timezone && !!this.timezones && !!this.user) {
            this.timezone = this.user?.timeZone
                ? (this.timezones.find(
                      (timezoneModel: IReferenceTimeZoneModel) =>
                          this.user?.timeZone && timezoneModel.timeZone.includes(this.user?.timeZone)
                  ) ?? null)
                : null;
        }
        this.prevTimeZone = this.timezone;
    }
}
