import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TagModel, TagUtil } from '@libs/vc-core-lib';

@Component({
    selector: 'vc-chip-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent {
    displayTags: TagModel[] = [];

    @Input()
    set excludeTypes(types: TagUtil[]) {
        this._excludeTypes = types;
        this.displayTags = TagUtil.convertArrayStringToTags(this.tags, types);
    }
    get excludeTypes(): TagUtil[] {
        return this._excludeTypes;
    }
    private _excludeTypes: TagUtil[] = [];

    @Input()
    set tags(value: string[]) {
        this._tags = value;
        this.displayTags = TagUtil.convertArrayStringToTags(value, this.excludeTypes);
    }
    get tags(): string[] {
        return this._tags;
    }
    private _tags: string[] = [];

    @Input()
    ariaLabel!: string;

    @Input()
    tooltipPosition: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' = 'above';

    @Input()
    showCount: number = 5;

    @HostBinding('class.wrap-tags')
    @Input()
    wrap: boolean = true;

    get notVisibleTagsTooltip(): string {
        return this.displayTags
            .slice(this.showCount)
            .map((value: TagModel) => value.displayValue)
            .join(', ');
    }
}
