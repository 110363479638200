<div class="vc-chart-empty-container">
    <div class="vc-chart-no-data">
        <span class="material-icons" aria-hidden="false" size="24px" style="color: var(--text-placeholder)"
            >bar_chart</span
        >
        <span class="vc-chart-no-data-text body-s-1">{{ noDataMsg }}</span>
    </div>
    @if (showChartLine) {
        <div class="vc-chart-empty-background" style="width: 100%; height: 80px"></div>
    }
    <div class="vc-chart-empty-actions" [class.space-top-16]="!showChartLine">
        @if (showSecondaryButton) {
            <vc-button mode="basic" [label]="secondaryLabel" (trigger)="secondaryTrigger.emit()"></vc-button>
        }
        @if (showPrimaryButton) {
            <vc-button mode="primary" [label]="primaryLabel" (trigger)="primaryTrigger.emit()"></vc-button>
        }
    </div>
</div>
