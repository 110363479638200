<div class="vc-landing-container">
    <h1 class="vc-landing-header headline-l vc-landing-color-high" i18n="@@LANDING.DASHBOARD.TITLE.DASHBOARD">
        Dashboard
    </h1>
    <div class="vc-landing-widgets-wrapper vc-grid-layout">
        <!-- Assets Count -->
        <div class="col-4 space-pd-top-0 space-pd-left-0">
            <vc-chart-wrapper [contentHeight]="'299px'" [vcProgressSpinner]="landingChartType.assetCountLoader">
                <h2
                    chartActionsTitle
                    class="title-l vc-landing-align-left"
                    i18n="@@LANDING.DASHBOARD.TITLE.ASSET_COUNT">
                    Asset count
                </h2>
                <span
                    chartActionsRightSide
                    class="body-m-1 vc-landing-align-right"
                    i18n="@@LANDING.DASHBOARD.LAST_30_DAYS"
                    >Last 30 days</span
                >
                <div class="vc-landing-chart-count">
                    <div class="display-xs vc-landing-color-high">{{ assetsTotalCount | number }}</div>
                    <vc-link
                        class="label-l"
                        color="var(--primary-700)"
                        label="View all assets"
                        i18n-label="@@LANDING.DASHBOARD.VIEW_ALL_ASSETS"
                        [target]="'_self'"
                        [routerLink]="'/asset/search'">
                    </vc-link>
                </div>
            </vc-chart-wrapper>
        </div>

        <!-- Assets By Type Chart -->
        <div class="col-4 space-pd-top-0">
            <vc-chart-wrapper [contentHeight]="'299px'" [vcProgressSpinner]="landingChartType.assetsByTypeLoader">
                <h2 chartActionsTitle class="title-l vc-landing-align-left" i18n="@@LANDING.CHART.TITLE.ASSETS_BY_TYPE">
                    Assets by type
                </h2>
                <span
                    chartActionsRightSide
                    class="body-m-1 vc-landing-align-right"
                    i18n="@@LANDING.DASHBOARD.LAST_30_DAYS"
                    >Last 30 days</span
                >
                <highcharts-chart
                    class="vc-landing-chart no-title no-data"
                    [Highcharts]="Highcharts"
                    [options]="assetsByTypeChartOptions"
                    [update]="!landingChartType.assetsByTypeLoader">
                </highcharts-chart>
            </vc-chart-wrapper>
        </div>

        <!-- Riskiest Assets Widget -->
        <div class="col-4 space-pd-top-0 space-pd-right-0">
            <vc-chart-wrapper [contentHeight]="'299px'" [vcProgressSpinner]="landingChartType.riskiestAssetLoader">
                <h2 chartActionsTitle class="title-l vc-landing-title" i18n="@@LANDING.DASHBOARD.TITLE.RISKIEST_ASSETS">
                    Riskiest assets
                </h2>
                <vc-select
                    label="Timeframe"
                    chartActionsRightSide
                    i18n-label="@@LANDING.TIME_FRAME"
                    class="vc-landing-timeframe-selector"
                    [minimalStyles]="true"
                    [options]="intervals"
                    [itemRenderer]="intervalsItemRenderer"
                    [(value)]="landingChartType.riskiestAsset"
                    (valueChange)="fetchRiskiestAssets()"></vc-select>
                <div class="vc-landing-riskiest-assets-items">
                    @for (asset of riskiestAssets; track asset) {
                        <div class="vc-landing-chart-asset">
                            <vc-icon
                                [name]="assetTypeIconRenderer(asset.type)"
                                [tooltip]="assetTypeItemRenderer(asset.type)"
                                color="var(--primary-600)"></vc-icon>
                            <vc-link
                                class="body-m-1"
                                color="var(--text-high-emphasis)"
                                padding="12px"
                                [label]="asset.displayName"
                                [tooltip]="asset.displayName"
                                [target]="'_self'"
                                (trigger)="onRiskiestAssetClick(asset.id)">
                            </vc-link>
                        </div>
                    } @empty {
                        <vc-chart-empty></vc-chart-empty>
                    }
                </div>
            </vc-chart-wrapper>
        </div>

        <!-- Findings by severity -->
        <div class="col-6 space-pd-left-0">
            <vc-chart-wrapper
                title="Findings by severity"
                i18n-title="@@LANDING.CHART.TITLE.FINDINGS_BY_SEVERITY"
                [contentHeight]="'299px'"
                [vcProgressSpinner]="landingChartType.findingChartLoader">
                <vc-select
                    class="vc-landing-timeframe-selector space-pd-0"
                    chartActionsRightSide
                    label="Timeframe"
                    i18n-label="@@LANDING.TIME_FRAME"
                    [minimalStyles]="true"
                    [(value)]="findingsBySeverityTimeFrame"
                    [options]="intervals"
                    [itemRenderer]="intervalsItemRenderer"
                    (valueChange)="
                        landingChartType.findingsBySeverity != findingsBySeverityTimeFrame &&
                            fetchFindingsBySeverityChart($event)
                    "></vc-select>
                <highcharts-chart
                    class="vc-landing-chart no-title no-data"
                    [Highcharts]="Highcharts"
                    [options]="findingsBySeverityChartOptions"
                    [update]="!landingChartType.findingChartLoader">
                </highcharts-chart>
            </vc-chart-wrapper>
        </div>

        <!-- Findings by categories -->
        <div class="col-6 space-pd-right-0">
            <vc-chart-wrapper
                title="Findings by categories"
                i18n-title="@@LANDING.CHART.TITLE.FINDINGS_BY_CATEGORIES"
                contentHeight="299px"
                [vcProgressSpinner]="landingChartType.findingChartLoader">
                <vc-select
                    class="vc-landing-timeframe-selector space-pd-0 no-data"
                    chartActionsRightSide
                    label="Timeframe"
                    i18n-label="@@LANDING.TIME_FRAME"
                    [minimalStyles]="true"
                    [(value)]="findingsByCategoryTimeFrame"
                    [options]="intervals"
                    [itemRenderer]="intervalsItemRenderer"
                    (valueChange)="
                        landingChartType.findingsByCategories != findingsByCategoryTimeFrame &&
                            fetchFindingsByCategoriesChart($event)
                    "></vc-select>
                <highcharts-chart
                    class="vc-landing-chart no-title no-data"
                    [Highcharts]="Highcharts"
                    [options]="findingsByCategoriesChartOptions"
                    [update]="!landingChartType.findingChartLoader">
                </highcharts-chart>
            </vc-chart-wrapper>
        </div>

        <!-- Events/Findings Chart -->
        <div class="col-12 space-pd-x-0">
            <vc-chart-wrapper
                title="Events / Findings"
                i18n-title="@@LANDING.CHART.TITLE.EVENTS/FINDINGS"
                contentHeight="299px"
                [vcProgressSpinner]="landingChartType.eventFindingChartLoader">
                <vc-select
                    chartActionsRightSide
                    label="Timeframe"
                    i18n-label="@@LANDING.TIME_FRAME"
                    [minimalStyles]="true"
                    [(value)]="landingChartType.eventFindingChart"
                    [options]="intervals"
                    [itemRenderer]="intervalsItemRenderer"
                    (valueChange)="fetchEventFindingChart($event)"></vc-select>
                <highcharts-chart
                    *ngIf="!landingChartType.eventFindingChartLoader"
                    class="vc-landing-chart no-title no-data"
                    [Highcharts]="Highcharts"
                    [options]="eventsFindingsChartOptions"
                    [update]="!landingChartType.eventFindingChartLoader">
                </highcharts-chart>
            </vc-chart-wrapper>
        </div>
    </div>
</div>
