import { Component, OnDestroy, OnInit } from '@angular/core';
import Highcharts, {
    SeriesAreaOptions,
    SeriesBarOptions,
    SeriesClickCallbackFunction,
    SeriesClickEventObject,
    SeriesPieOptions,
    XAxisOptions,
    XAxisTitleOptions,
    YAxisOptions,
} from 'highcharts';
import { Subject, finalize, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash-es';
import { DatePipe } from '@angular/common';
import {
    AssetTypes,
    AssetTypesHelper,
    DateTimeInterval,
    DateUtil,
    IKeyLongValueModel,
    TimeInterval,
    Severity,
    SeverityHelper,
    TimeIntervalHelper,
    DayHelper,
    Day,
    AssetOriginType,
    CommonUtil,
    IKeyValueGeneric,
} from '@libs/vc-core-lib';
import { NotificationService } from '@libs/vc-common-ui-lib';
import { AssetService, IAssetModel } from '@libs/vc-asset-lib';
import { FindingService, IFindingDashboardResultsModel } from '@libs/vc-finding-lib';
import { EventService, IDashboardModel, IDateCountMapModel } from '@libs/vc-event-lib';
import NoDataOptions from 'highcharts/modules/no-data-to-display';

@Component({
    selector: 'vc-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    providers: [AssetService, FindingService, EventService, DatePipe],
})
export class LandingComponent implements OnInit, OnDestroy {
    private _destroy$: Subject<any> = new Subject<any>();
    readonly noDataAvailableText = $localize`:@@COMMON_UI.TABLE.NO_DATA_AVAILABLE:No data available`;

    Highcharts = Highcharts;

    toDate!: string;
    dateTimeInterval!: string;
    assetsTotalCount: number = 0;
    findingsBySeverityTimeFrame: TimeInterval | null = TimeInterval.WEEK;
    findingsByCategoryTimeFrame: TimeInterval | null = TimeInterval.WEEK;
    intervals = [TimeInterval.DAY, TimeInterval.WEEK, TimeInterval.MONTH];
    intervalsItemRenderer = (timeInterval: TimeInterval): string =>
        TimeIntervalHelper.getTimeIntervalLocale(timeInterval);
    riskiestAssets: Array<IAssetModel> = [];

    landingChartType: ILandingChartType = {
        riskiestAsset: TimeInterval.WEEK,
        findingsByCategories: TimeInterval.WEEK,
        findingsBySeverity: TimeInterval.WEEK,
        eventFindingChart: TimeInterval.WEEK,
        assetCountLoader: false,
        assetsByTypeLoader: false,
        riskiestAssetLoader: false,
        findingChartLoader: false,
        eventFindingChartLoader: false,
    };

    // TODO: Move to _initializeHighcharts() after refactoring all charts to use new empty state UI
    noDataTemplate: string =
        '<div class="vc-chart-empty-container">' +
        '   <div class="vc-chart-no-data">' +
        '       <span class="material-icons vc-chart-empty-icon">bar_chart</span>' +
        `       <span class="vc-chart-no-data-text body-s-1">${this.noDataAvailableText}</span>` +
        '   </div>' +
        '   <div class="vc-chart-empty-background" style="width: 100%; height: 80px"></div>' +
        '</div>';

    assetsByTypeChartOptions: Highcharts.Options = {
        chart: {
            type: 'pie',
            backgroundColor: '',
            borderWidth: 0,
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                events: {
                    click: ((event: SeriesClickEventObject) => {
                        this._router.navigate(['/asset/search'], {
                            queryParams: {
                                type: event.point.options.id,
                                showBackLink: true,
                            },
                        });
                    }) as SeriesClickCallbackFunction,
                },
            },
            pie: {
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                innerSize: '50%',
                size: '100%',
                borderWidth: 0,
            },
        },
        legend: {
            width: '40%',
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'middle',
        },
        tooltip: {
            format: '{point.name}: <b>{point.y}</b>',
        },
        lang: {
            noData: this.noDataTemplate,
        },
        exporting: {
            enabled: false,
        },
        series: [
            {
                name: $localize`:@@LANDING.CHART.TITLE.ASSETS_BY_TYPE:Assets by type`,
                data: [],
                type: 'pie',
            },
        ],
    };

    findingsBySeverityChartOptions: Highcharts.Options = {
        chart: {
            type: 'pie',
            backgroundColor: '',
            borderWidth: 0,
            events: {
                render: (e: any) => {
                    const element: SVGElement = Array.from(e.target.container.children).find((element: any) =>
                        Array.from(element.classList).includes('highcharts-root')
                    ) as SVGElement;

                    if (
                        Array.from(e.target.container.children as HTMLCollection).some((element: Element): boolean => {
                            return Array.from(element.classList).includes('highcharts-no-data');
                        })
                    ) {
                        element.style.display = 'none';
                    } else {
                        element.style.display = '';
                    }
                },
            },
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                events: {
                    click: (event: SeriesClickEventObject) => {
                        this._router.navigate([`/finding/search`], {
                            queryParams: {
                                fromInclusive: this._setFromDate(this.landingChartType.findingsBySeverity),
                                toExclusive: this.toDate,
                                severity: event.point.name,
                            },
                        });
                    },
                },
            },
            pie: {
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                innerSize: '50%',
                size: '100%',
                borderWidth: 0,
            },
        },
        lang: {
            noData: this.noDataTemplate,
        },
        exporting: {
            enabled: false,
        },
        legend: {
            width: '40%',
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'middle',
        },
        tooltip: {
            format: '{point.name}: <b>{point.y}</b>',
        },
        series: [
            {
                name: $localize`:@@LANDING.CHART.TITLE.FINDINGS_BY_SEVERITY:Findings by severity`,
                data: [],
                type: 'pie',
                colorKey: 'color',
            },
        ],
    };

    findingsByCategoriesChartOptions: Highcharts.Options = {
        chart: {
            type: 'bar',
            backgroundColor: '',
            borderWidth: 0,
        },
        title: {
            text: '',
        },
        xAxis: {
            type: 'category',
            categories: [],
            labels: {
                y: -13,
                x: 0,
                align: 'left',
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
            },
            title: {
                text: '',
            },
            lineWidth: 0,
            scrollbar: {
                enabled: false,
            },
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                enabled: true,
                format: '{value:,.0f}',
            },
            stackLabels: {
                enabled: false,
            },
            gridLineColor: CommonUtil.getRenderedColor('--outline'),
        },
        plotOptions: {
            bar: {
                pointWidth: 11,
                colors: [
                    CommonUtil.getRenderedColor('--success-text-color'),
                    CommonUtil.getRenderedColor('--secondary-600'),
                    CommonUtil.getRenderedColor('--primary-700'),
                    CommonUtil.getRenderedColor('--primary-400'),
                    CommonUtil.getRenderedColor('--secondary-400'),
                ],
                colorByPoint: true,
            },
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                },
                events: {
                    click: (event: SeriesClickEventObject) => {
                        this._router.navigate([`/finding/search`], {
                            queryParams: {
                                fromInclusive: this._setFromDate(this.landingChartType.findingsByCategories),
                                toExclusive: this.toDate,
                                category: event.point.category,
                            },
                        });
                    },
                },
            },
        },
        lang: {
            noData: this.noDataTemplate,
        },
        exporting: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            format: '{point.category}: <b>{point.y}</b>',
        },
        series: [
            {
                name: $localize`:@@LANDING.CHART.TITLE.FINDINGS_BY_CATEGORIES:Findings by categories`,
                data: [],
                type: 'bar',
            },
        ],
    };

    eventsFindingsChartOptions: Highcharts.Options = {
        chart: {
            type: 'areaspline',
            backgroundColor: '',
            borderWidth: 0,
            events: {
                load: (e: any) => {
                    const element: HTMLElement = Array.from(e.target?.container.children as HTMLCollection).find(
                        (element: Element) => Array.from(element.classList).includes('highcharts-root')
                    ) as HTMLElement;

                    if (isEmpty(e.target.series) || isEmpty(e.target.series[0].data)) {
                        element.style.display = 'none';
                    } else {
                        element.style.display = '';
                    }
                },
            },
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            shared: false,
            headerFormat: '<b>{point.x.id}</b><br>',
        },
        legend: {
            enabled: false,
        },
        lang: {
            noData: this.noDataTemplate,
        },
        xAxis: {
            categories: [],
            title: {
                text: $localize`:@@CORE.DAY:Day`,
            },
            labels: {
                format: '{value.name}',
            },
        },
        yAxis: [
            {
                title: {
                    text: $localize`:@@LANDING.CHART.TITLE.EVENTS:Events`,
                },
                labels: {
                    format: '{value:,.0f}',
                },
                min: 0,
                max: 0,
            },
            {
                title: {
                    text: $localize`:@@LANDING.CHART.TITLE.FINDINGS:Findings`,
                },
                labels: {
                    format: '{value:,.0f}',
                },
                opposite: true,
                min: 0,
                max: 0,
            },
        ],
        plotOptions: {
            series: {
                stickyTracking: false,
                events: {
                    click: (event: SeriesClickEventObject) => {
                        this.onEventsFindingsSeriesClick(event);
                    },
                },
            },
        },
        exporting: {
            enabled: false,
        },
        series: [
            {
                marker: {
                    enabled: false,
                },
                data: [{}],
                name: $localize`:@@LANDING.CHART.TITLE.EVENTS:Events`,
                color: CommonUtil.getRenderedColor('--primary-200'),
                yAxis: 0,
                type: 'areaspline',
            },
            {
                marker: {
                    enabled: false,
                },
                data: [{}],
                name: $localize`:@@LANDING.CHART.TITLE.FINDINGS:Findings`,
                color: CommonUtil.getRenderedColor('--primary-700'),
                yAxis: 1,
                type: 'areaspline',
            },
        ],
    };

    constructor(
        private _assetService: AssetService,
        private _findingService: FindingService,
        private _eventService: EventService,
        private _datePipe: DatePipe,
        private _router: Router,
        private _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        NoDataOptions(Highcharts);
        this.fetchAssetsTotalCount();
        this.fetchAssetsByType();
        this.fetchFindingCharts(TimeInterval.WEEK);
    }

    fetchAssetsTotalCount(): void {
        this.landingChartType.assetCountLoader = true;
        this._assetService
            .getAssetsTotalCount(this._setFromDate(TimeInterval.MONTH), this.toDate)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => {
                    this.landingChartType.assetCountLoader = false;
                })
            )
            .subscribe({
                next: (value: number) => {
                    this.assetsTotalCount = value;
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@LANDING.DASHBOARD.ASSET_COUNT.FETCH_ERROR:Error while fetching asset count.`
                    );
                },
            });
    }

    fetchAssetsByType(): void {
        this.assetsByTypeChartOptions.series &&
            ((this.assetsByTypeChartOptions.series[0] as SeriesPieOptions).data = []);
        this.landingChartType.assetsByTypeLoader = true;
        this._assetService
            .getAssetsCountByType(this._setFromDate(TimeInterval.MONTH), this.toDate)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => (this.landingChartType.assetsByTypeLoader = false))
            )
            .subscribe({
                next: (value: IKeyValueGeneric) => {
                    this._renderAssetsByTypeChart(value);
                    if (!isEmpty(value) && this.assetsByTypeChartOptions.plotOptions?.pie) {
                        this.assetsByTypeChartOptions.plotOptions.pie.borderWidth = 1;
                    }
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@LANDING.DASHBOARD.ASSETS_BY_TYPE.FETCH_ERROR:Error while fetching assets count by type.`
                    );
                },
            });
    }

    private _renderAssetsByTypeChart(assetsByTypeResults: IKeyValueGeneric): void {
        const assetByTypeColor: Map<string, string> = new Map<string, string>()
            .set(AssetTypes.NETWORK, '--primary-200')
            .set(AssetTypes.HOST, '--secondary-700')
            .set(AssetTypes.ENDPOINT, '--primary-300')
            .set(AssetTypes.APPLICATION, '--primary-700')
            .set(AssetTypes.USER, '--primary-400')
            .set(AssetTypes.UTM, '--secondary-300')
            .set(AssetTypes.SITE, '--secondary-50');
        const data: { id: string; name: string; y: number; color: string }[] = [];
        Object.entries(assetsByTypeResults).forEach(([value, count]) => {
            data.push({
                id: value,
                name: this.assetTypeItemRenderer(value),
                y: Number(count),
                color: CommonUtil.getRenderedColor(assetByTypeColor.get(value) ?? ''),
            });
        });
        this.assetsByTypeChartOptions.series &&
            ((this.assetsByTypeChartOptions.series[0] as SeriesPieOptions).data = data);
    }

    fetchRiskiestAssets(): void {
        this.landingChartType.riskiestAssetLoader = true;
        this.riskiestAssets = [];
        this._assetService
            .getsRiskiestAssets(this._setFromDate(this.landingChartType.riskiestAsset!), this.toDate)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => (this.landingChartType.riskiestAssetLoader = false))
            )
            .subscribe({
                next: (assets: Array<IAssetModel>) => {
                    this.riskiestAssets = assets;
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@LANDING.DASHBOARD.RISKIEST_ASSETS.FETCH_ERROR:Error while fetching the riskiest assets.`
                    );
                },
            });
    }

    fetchFindingsBySeverityChart(timeInterval: TimeInterval | null) {
        this.landingChartType.findingsBySeverity = timeInterval;
        this.fetchFindingCharts(this.landingChartType.findingsBySeverity);
    }

    fetchFindingsByCategoriesChart(timeInterval: TimeInterval | null) {
        this.landingChartType.findingsByCategories = timeInterval;
        this.fetchFindingCharts(this.landingChartType.findingsByCategories);
    }

    fetchFindingCharts(timeInterval: TimeInterval | null): void {
        if (!timeInterval) return;

        this.landingChartType.findingChartLoader = true;
        this._findingService
            .getDashboardResults(this._setFromDate(timeInterval), this.toDate, this.dateTimeInterval)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => (this.landingChartType.findingChartLoader = false))
            )
            .subscribe({
                next: (facetedFindings: IFindingDashboardResultsModel) => {
                    this._buildFindingCharts(facetedFindings, timeInterval);
                    if (!isEmpty(facetedFindings.bySeverity) && this.findingsBySeverityChartOptions.plotOptions?.pie) {
                        this.findingsBySeverityChartOptions.plotOptions.pie.borderWidth = 1;
                    }
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@LANDING.DASHBOARD.FINDING.FETCH_ERROR:Error while fetching the finding dashboard result.`
                    );
                },
            });
    }

    private _buildFindingCharts(facets: IFindingDashboardResultsModel, timeInterval: TimeInterval): void {
        if (this.landingChartType.findingsBySeverity === timeInterval) {
            this._renderFindingsBySeverityChart(facets.bySeverity);
        }
        if (this.landingChartType.findingsByCategories === timeInterval) {
            this._renderFindingsByCategoriesChart(Object.values(facets.byCategory));
        }
    }

    private _renderFindingsBySeverityChart(facets: Map<Severity, number>): void {
        this.findingsBySeverityChartOptions.series &&
            ((this.findingsBySeverityChartOptions.series[0] as SeriesPieOptions).data = []);
        const severities = Object.values(Severity).reverse();
        const findingsBySeverityColors: Map<string, string> = new Map<string, string>()
            .set(Severity.CRITICAL, '#A40A02') // CRITICAL
            .set(Severity.HIGH, CommonUtil.getRenderedColor('--error-text-color')) // HIGH
            .set(Severity.MEDIUM, '#EEA417') // MEDIUM
            .set(Severity.LOW, CommonUtil.getRenderedColor('--success-color')) // LOW
            .set(Severity.INFO, CommonUtil.getRenderedColor('--primary-700')) // INFO
            .set(Severity.UNKNOWN, CommonUtil.getRenderedColor('--secondary-200')); // UNKNOWN

        if (!isEmpty(facets) && this.findingsBySeverityChartOptions.series) {
            (this.findingsBySeverityChartOptions.series[0] as SeriesPieOptions).data = Object.entries(facets)
                .map(([key, value]) => {
                    return {
                        id: key,
                        name: SeverityHelper.getSeverityLocale(key as Severity),
                        y: Number(value),
                        color: findingsBySeverityColors.get(key) ?? '',
                    } as { id: Severity; name: string; y: number; color: string };
                })
                .sort(
                    (facetA, facetB) =>
                        severities.indexOf(facetA.id as Severity) - severities.indexOf(facetB.id as Severity)
                );
        }
    }

    private _renderFindingsByCategoriesChart(facets: IKeyLongValueModel[]): void {
        this.findingsByCategoriesChartOptions.xAxis &&
            ((this.findingsByCategoriesChartOptions.xAxis as XAxisOptions).categories = []);
        this.findingsByCategoriesChartOptions.series &&
            ((this.findingsByCategoriesChartOptions.series[0] as SeriesBarOptions).data = []);

        if (!isEmpty(facets)) {
            // Category limit to be shown on chart is set to 5
            (this.findingsByCategoriesChartOptions.xAxis as XAxisOptions).categories = facets
                .map((facet) => facet.key)
                .filter((facet, index) => index <= 4);
            this.findingsByCategoriesChartOptions.series &&
                ((this.findingsByCategoriesChartOptions.series[0] as SeriesBarOptions).data = facets
                    .map((facet) => facet.value)
                    .filter((facet, index) => index <= 4));
        }
    }

    fetchEventFindingChart(timeInterval: TimeInterval | null): void {
        if (!timeInterval) return;

        if (timeInterval === TimeInterval.DAY) {
            ((this.eventsFindingsChartOptions.xAxis as XAxisOptions).title as XAxisTitleOptions).text =
                $localize`:@@CORE.HOUR:Hour`;
        } else if (timeInterval === TimeInterval.WEEK) {
            ((this.eventsFindingsChartOptions.xAxis as XAxisOptions).title as XAxisTitleOptions).text =
                $localize`:@@CORE.DAY:Day`;
        } else if (timeInterval === TimeInterval.MONTH) {
            ((this.eventsFindingsChartOptions.xAxis as XAxisOptions).title as XAxisTitleOptions).text =
                $localize`:@@CORE.DATE:Date`;
        }

        (this.eventsFindingsChartOptions.xAxis as XAxisOptions).categories = [];
        if (this.eventsFindingsChartOptions.series) {
            (this.eventsFindingsChartOptions.series[0] as SeriesAreaOptions).data = [];
            (this.eventsFindingsChartOptions.series[1] as SeriesAreaOptions).data = [];
        }

        this.landingChartType.eventFindingChartLoader = true;
        const fromDate = this._setFromDate(timeInterval);

        this._eventService
            .getDashboardResult(fromDate, this.toDate, this.dateTimeInterval)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => {
                    this.landingChartType.eventFindingChartLoader = false;
                })
            )
            .subscribe({
                next: (dashboardResult: IDashboardModel) => {
                    if (dashboardResult && !isEmpty(dashboardResult.dateCountMapsByGroup)) {
                        this.renderEventsFindingsChart(fromDate, dashboardResult.dateCountMapsByGroup, timeInterval);
                    }
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@LANDING.DASHBOARD.EVENT.FETCH_ERROR:Error while fetching event dashboard result.`
                    );
                },
            });
    }

    private renderEventsFindingsChart(
        from: string,
        dateCountMapsByGroup: IDateCountMapModel[],
        timeInterval: TimeInterval
    ): void {
        for (
            let i = DateUtil.getUtcDate(from);
            i <= DateUtil.getUtcDate(this.toDate);
            timeInterval === TimeInterval.DAY ? i.setHours(i.getHours() + 1) : i.setDate(i.getDate() + 1)
        ) {
            const dateCondition =
                timeInterval === TimeInterval.DAY
                    ? this._datePipe.transform(i, 'yyyy-MM-ddTHH') + ':00:00Z'
                    : this._datePipe.transform(i, 'yyyy-MM-dd') + 'T00:00:00Z';
            const matchingCount = dateCountMapsByGroup.find((count) => count.date === dateCondition);
            if (
                matchingCount &&
                this.eventsFindingsChartOptions.series &&
                !isEmpty(this.eventsFindingsChartOptions.series)
            ) {
                (this.eventsFindingsChartOptions.series[0] as SeriesAreaOptions).data?.push(
                    matchingCount.eventCountByGroup['EVENTS'] ?? 0
                );
                (this.eventsFindingsChartOptions.series[1] as SeriesAreaOptions).data?.push(
                    matchingCount.eventCountByGroup['ESCALATIONS'] ?? 0
                );
            }

            (this.eventsFindingsChartOptions.xAxis as XAxisOptions)?.categories?.push(
                this._getCategoryName(timeInterval, i)
            );
        }

        if (this.eventsFindingsChartOptions.yAxis && this.eventsFindingsChartOptions.series) {
            (this.eventsFindingsChartOptions.yAxis as YAxisOptions[])[0].max = !isEmpty(
                (this.eventsFindingsChartOptions.series[0] as SeriesAreaOptions).data
            )
                ? Math.max(...((this.eventsFindingsChartOptions.series[0] as SeriesAreaOptions).data as number[]))
                : 5;
            (this.eventsFindingsChartOptions.yAxis as YAxisOptions[])[1].max = !isEmpty(
                (this.eventsFindingsChartOptions.series[1] as SeriesAreaOptions).data
            )
                ? Math.max(...((this.eventsFindingsChartOptions.series[1] as SeriesAreaOptions).data as number[]))
                : 5;
        }
    }

    onEventsFindingsSeriesClick(event: SeriesClickEventObject): void {
        const fromDate = new Date(Object.values(event.point.category)[2]);
        const toDate = new Date(fromDate);
        this.landingChartType.eventFindingChart === TimeInterval.DAY
            ? toDate.setHours(toDate.getHours() + 1)
            : toDate.setDate(toDate.getDate() + 1);

        if (event.point.series.name === $localize`:@@LANDING.CHART.TITLE.EVENTS:Events`) {
            this.navigateToEventsSearch(fromDate.toISOString(), toDate.toISOString());
        } else if (event.point.series.name === $localize`:@@LANDING.CHART.TITLE.FINDINGS:Findings`) {
            this.navigateToFindingsSearch(fromDate.toISOString(), toDate.toISOString());
        }
    }

    async navigateToFindingsSearch(fromDate: string, toDate: string) {
        await this._router.navigate([`/finding/search`], {
            queryParams: {
                fromInclusive: fromDate,
                toExclusive: toDate,
            },
        });
    }

    async navigateToEventsSearch(fromDate: string, toDate: string) {
        await this._router.navigate([`event/search`], {
            queryParams: {
                timestampAfter: fromDate,
                timestampBefore: toDate,
            },
        });
    }

    async onRiskiestAssetClick(id: string) {
        await this._router.navigate([`asset/view/details`], {
            queryParams: { assetId: id, assetOriginType: AssetOriginType.ASSET_LANDING },
        });
    }

    assetTypeItemRenderer = (type: string): string => AssetTypesHelper.getAssetTypeLocale(type as AssetTypes);
    assetTypeIconRenderer = (type: string): string => AssetTypesHelper.getAssetTypeIcon(type as AssetTypes);

    private _getCategoryName = (timeInterval: TimeInterval, date: Date): string => {
        switch (timeInterval) {
            case TimeInterval.DAY:
                return this._datePipe.transform(date, 'H') ?? '';
            case TimeInterval.WEEK:
                return DayHelper.getDayLocale((this._datePipe.transform(date, 'EEEE')?.toUpperCase() as Day) ?? '');
            case TimeInterval.MONTH:
                return date.getDate().toString();
            default:
                return '';
        }
    };

    private _setFromDate(sortBy: TimeInterval | null): string {
        if (!sortBy) return '';

        this.toDate = new Date().toISOString();
        const fromDate = new Date(this.toDate);
        switch (sortBy) {
            case TimeInterval.DAY:
                fromDate.setHours(fromDate.getHours() - 23);
                this.dateTimeInterval = DateTimeInterval.HOUR;
                break;
            case TimeInterval.WEEK:
                fromDate.setDate(fromDate.getDate() - 6);
                this.dateTimeInterval = DateTimeInterval.DAY;
                break;
            case TimeInterval.MONTH:
                fromDate.setMonth(fromDate.getMonth() - 1);
                fromDate.setDate(fromDate.getDate() + 1);
                this.dateTimeInterval = DateTimeInterval.DAY;
                break;
        }
        return fromDate.toISOString();
    }

    ngOnDestroy(): void {
        this._destroy$.next(null);
        this._destroy$.complete();
    }
}

interface ILandingChartType {
    riskiestAsset: TimeInterval | null;
    findingsBySeverity: TimeInterval | null;
    findingsByCategories: TimeInterval | null;
    eventFindingChart: TimeInterval | null;
    assetCountLoader: boolean;
    assetsByTypeLoader: boolean;
    riskiestAssetLoader: boolean;
    findingChartLoader: boolean;
    eventFindingChartLoader: boolean;
}
