import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

export declare type ButtonMode = 'primary' | 'basic' | 'icon' | 'text' | 'danger' | 'danger-text';

@Component({
    selector: 'vc-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [MatButton, MatTooltip, MatMenuTrigger, MatIconButton, MatIcon],
})
export class ButtonComponent {
    @Input()
    mode: ButtonMode = 'primary';

    /** Specifies the type of button(button | submit | reset). */
    @Input()
    type = 'button';

    /** Whether to set the style of the button as a link.  Will override height input.  Works best with mode set to 'text' */
    @Input()
    inline: boolean = false;

    /** Input label. */
    @Input()
    label: string = '';

    /** Attribute defines a string value that labels button */
    @Input()
    ariaLabel!: string;

    /** Identifies the element (or elements) that describes the button */
    @Input()
    ariaDescribedby!: string;

    /** Whether the component is disabled. */
    @Input()
    disabled: boolean = false;

    /** Whether to show loading. */
    @Input()
    loading: boolean = false;

    /** Name of icon from material ui icons list */
    @Input()
    iconName: string = '';

    /** Icon color */
    @Input()
    iconColor: string = '';

    /** Specifies button text color */
    @Input()
    textColor!: string;

    @Input()
    fontSize: string = '14px';

    /** Specifies text-decoration as underline */
    @Input()
    underlineText = false;

    /** Specify the tabindex of anchor tag */
    @Input()
    tabIndex!: string;

    /** Specifies tooltip text for tooltip upon hovering */
    @Input()
    tooltip!: string;

    /** Specifies button badge icon, what displays in top right corner */
    @Input()
    badgeIcon!: string | null;

    /** Specifies button height */
    @Input()
    height: string = '40px';

    /** Specifies button width */
    @Input()
    width!: string;

    /** Specifies button icon prefix, applies only for 'PRIMARY' and 'BASIC' buttons */
    @Input()
    iconPrefix!: string;

    /** Specifies button icon suffix, applies only for 'PRIMARY' and 'BASIC' buttons */
    @Input()
    iconSuffix!: string;

    /** Specifies tooltip position.  Values: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' */
    @Input()
    tooltipPosition: TooltipPosition = 'below';

    @Input()
    triggerFor: MatMenuPanel | null = null;

    /** Event is fired when button click, enter press or space press */
    @Output()
    trigger = new EventEmitter<Event>();

    @HostBinding('class.button-badge')
    get className() {
        return this.badgeIcon != null;
    }

    get buttonMode(): ButtonMode {
        return this.mode === 'text' || this.mode === 'danger' || this.mode === 'danger-text' ? 'basic' : this.mode;
    }
}
