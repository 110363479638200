import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    CommonUtil,
    IUserModel,
    Language,
    Theme,
    UserContextService,
    UserPreferenceService,
    UserService,
    WhoAmI,
} from '@libs/vc-core-lib';
import { finalize, forkJoin, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { Breadcrumb, NotificationService } from '@libs/vc-common-ui-lib';
import { IReferenceTimeZoneModel, ReferenceService } from '@libs/vc-reference-data-lib';
import { IOrgModel, OrgService } from '@libs/vc-org-lib';

@Component({
    selector: 'vc-account-settings-view',
    templateUrl: './account-settings-view.component.html',
    styleUrls: ['./account-settings-view.component.scss'],
})
export class AccountSettingsViewComponent implements OnInit, OnDestroy {
    private _destroy$: Subject<void> = new Subject();
    breadcrumbs: Breadcrumb[] = [];
    loading: boolean = false;
    showEnableMFA: boolean = false;
    showDisableMFA: boolean = false;
    mfaEnabled: boolean = false;
    orgMFAEnabled: boolean = false;

    whoAmI!: WhoAmI | null;
    user!: IUserModel;
    orgs$!: Observable<IOrgModel[]>;
    timezones!: IReferenceTimeZoneModel[];
    theme$!: Observable<Theme | null>;
    previousTheme!: Theme | null;
    themes: Theme[] = Object.values(Theme);
    language!: Language | null;
    previousLanguage!: Language | null;
    languages: Language[] = Object.values(Language);

    constructor(
        private _orgService: OrgService,
        private _referenceService: ReferenceService,
        private _userService: UserService,
        private _userContextService: UserContextService,
        private _userPreferenceService: UserPreferenceService,
        private _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this._userContextService
            .getContext()
            .pipe(takeUntil(this._destroy$))
            .subscribe({
                next: (whoAmI: WhoAmI | null) => {
                    this.whoAmI = whoAmI;
                    this.language = this.previousLanguage = this.whoAmI?.locale ?? Language.EN;
                    if (whoAmI?.getOrgId()) {
                        this._orgService
                            .getOrgById(whoAmI.getOrgId())
                            .pipe(take(1))
                            .subscribe((org: IOrgModel) => {
                                this.orgMFAEnabled = org.usingMfa;
                            });
                    }
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@ACCOUNT.SETTINGS.ERROR_RETRIEVING_SETTINGS:There was an issue retrieving account details. Please try again.`
                    );
                },
            });

        this.orgs$ = this._orgService.getOrgsForSwitch();

        this.fetchUserDetails();

        this.theme$ = this._userPreferenceService.getUserTheme().pipe(tap((theme) => (this.previousTheme = theme)));
    }

    saveSettings(userSettings: UserSettings) {
        const requests = [];
        const languageChanged = userSettings.language !== this.previousLanguage;
        const themeChanged = this.whoAmI && userSettings.theme !== this.previousTheme;
        const username = this.user.userKey.username;
        if (!languageChanged && !themeChanged) {
            return;
        }

        this.loading = true;
        languageChanged && requests.push(this._userService.saveUserLocale(username, userSettings.language));
        themeChanged && requests.push(this._userPreferenceService.saveUserTheme(userSettings.theme));

        forkJoin(requests)
            .pipe(
                take(1),
                finalize(() => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                })
            )
            .subscribe(() => {
                if (languageChanged) {
                    CommonUtil.changeLanguage(userSettings.language);
                }
            });
    }

    updateUserDetails(user: IUserModel) {
        this.loading = true;
        this._userService
            .updateUserDetails(user)
            .pipe(
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe({
                next: () => {
                    this._notificationService.success(
                        '',
                        $localize`:@@ACCOUNT.DETAILS.SUCCESS_SAVING_DETAILS:Successfully saved account details`
                    );
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@ACCOUNT.DETAILS.ERROR_SAVING_DETAILS:There was an issue saving account details`
                    );
                },
            });
    }

    fetchUserDetails() {
        this._userService
            .getUserDetails()
            .pipe(
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe((user: IUserModel) => {
                this._getAllTimezones();
                this.user = user;
                this.mfaEnabled = user.usingMfa ?? false;
            });

        this.theme$ = this._userPreferenceService.getUserTheme().pipe(tap((theme) => (this.previousTheme = theme)));
    }

    _getAllTimezones() {
        this._referenceService
            .getAllTimeZones()
            .pipe(take(1))
            .subscribe((timezones: IReferenceTimeZoneModel[]) => {
                this.timezones = [...timezones].sort((a: IReferenceTimeZoneModel, b: IReferenceTimeZoneModel) => {
                    return parseFloat(b.offset.replace('UTC', '')) - parseFloat(a.offset.replace('UTC', ''));
                });
            });
    }

    disableMFA(verificationCode: string) {
        this.loading = false;
        this._userService
            .disableMfa(verificationCode)
            .pipe(
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe({
                next: () => {
                    this._notificationService.success(
                        '',
                        $localize`:@@ACCOUNT.ENABLE_MFA.DETAILS.SUCCESS_DISABLING_MFA:Successfully disabled MFA`
                    );
                    this.mfaEnabled = false;
                    this.fetchUserDetails();
                },
                error: () =>
                    this._notificationService.error(
                        '',
                        $localize`:@@ACCOUNT.ENABLE_MFA.DETAILS.ERROR:Please enter valid code`
                    ),
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}

export interface UserSettings {
    language: Language;
    theme: Theme;
}
