import { Directive, ElementRef, Input, ViewContainerRef } from '@angular/core';
import { ProgressSpinnerComponent } from '../../components/progress-spinner/progress-spinner.component';

/** INFO: To use vcProgress Spinner, make sure that container on what using this directive has position: relative style, otherwise spinner won't show up */
@Directive({
    selector: '[vcProgressSpinner]',
})
export class ProgressSpinnerDirective {
    private _spinnerElement!: HTMLElement;

    /** Whether to show spinner */
    @Input('vcProgressSpinner')
    set loading(value: boolean | null) {
        if (this._loading != value) {
            this._loading = value ?? false;
            this._updateView();
        }
    }

    private _loading = false;
    get loading(): boolean {
        return this._loading;
    }

    constructor(
        private _hostElement: ElementRef,
        private viewContainerRef: ViewContainerRef
    ) {}

    private _updateView() {
        if (!this._spinnerElement) {
            const spinnerFactory = this.viewContainerRef.createComponent(ProgressSpinnerComponent);

            this._spinnerElement = spinnerFactory?.location?.nativeElement;
            this._hostElement.nativeElement.style.position = 'relative';
            this._hostElement.nativeElement.prepend(this._spinnerElement);
        }

        this._spinnerElement.style.backgroundColor = 'var(--vc-loader-container-bg)';
        this._spinnerElement.style.borderRadius = '8px';
        this._spinnerElement.style.inset = '0';
        this._spinnerElement.style.display = this._loading ? 'flex' : 'none';
    }
}
